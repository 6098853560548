<template>
  <div style="height: 30rem">
    <div class="mt-24 flex justify-center" v-if="!showCertificate">
      <div class="flex items-center">
        <select
          class="px-4 py-6 bg-[#121212] text-white appearance-none text-center w-full font-extrabold text-lg"
          name="ooselect"
          v-model="g0"
          @input="g[0] = $event.target.value"
        >
          <option v-for="i in 10" :value="i - 1">
            {{
              ["💻", "🖥️", "⌨️", "🖱️", "🧑‍💻", "📱", "💾", "📡", "🧠", "🤖"][
                i - 1
              ]
            }}
          </option>
        </select>
        <select
          class="px-4 py-6 bg-[#121212] text-white appearance-none text-center w-full font-extrabold text-lg"
          name="ooselect"
          v-model="g3"
          @input="g[3] = $event.target.value"
        >
          <option v-for="i in 10" :value="i - 1">{{ i }}</option>
        </select>
        <select
          class="px-4 py-6 bg-[#121212] text-white appearance-none text-center w-full font-extrabold text-lg"
          name="ooselect"
          v-model="g1"
          @input="g[1] = $event.target.value"
        >
          <option v-for="i in projects.length" :value="i - 1">
            {{ projects[i - 1].title }}
          </option>
        </select>
        <select
          class="px-4 py-6 bg-[#121212] text-white appearance-none text-center w-full font-extrabold text-lg"
          name="ooselect"
          v-model="g2"
          @input="g[2] = $event.target.value"
        >
          <option :value="1">GitHub</option>
          <option :value="2">Spotify</option>
          <option :value="3">Mail</option>
        </select>
        <button
          class="px-4 py-4 bg-[#121212] text-white appearance-none text-center w-full font-extrabold text-lg"
          @click="check"
        >
          ✔
        </button>
      </div>
    </div>
    <div
      v-if="showNameModal"
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div
        class="bg-[#222222] rounded-lg p-8 max-w-md mx-auto animate-fade-in"
        @click.stop
      >
        <h2 class="text-2xl font-bold mb-4 text-center">
          🎉 Congratulations! 🎉
        </h2>
        <p class="mb-4 text-center">
          You have successfully completed the challenge! 🚀
        </p>
        <input
          v-model="userName"
          @keypress.enter="generateCertificate"
          type="text"
          placeholder="Your Name"
          class="border p-2 mb-4 w-full text-center"
        />
        <button
          @click="generateCertificate"
          class="bg-blue-500 text-white px-4 py-2 rounded w-full"
        >
          Generate Certificate
        </button>
        <button
          @click="showNameModal = false"
          class="bg-gray-500 text-white px-4 py-2 rounded w-full mt-2"
        >
          Close
        </button>
      </div>
    </div>

    <!-- Certificate Display -->
    <div v-if="showCertificate" class="mt-24 flex justify-center flex-col">
      <canvas
        id="certificateCanvas"
        class="w-1/2 ml-1/2 transform translate-x-1/2"
      ></canvas>
    </div>
  </div>
</template>

<script setup>
import { t, k, g, getOo } from "../lang.js";
import { projects, tech } from "./projects.js";
import { ref, onMounted } from "vue";
import BetterSelect from "../components/BetterSelect.vue";
//import { useRouter } from "vue-router";
//if (!getOo()) {
//  useRouter().push("/");
//}
let v0 = Math.floor(k[0] * 10);
let v1 = Math.floor(k[1] * (projects.length - 1)) + 1;
let v2 = Math.floor(k[2] * 3) + 1;
let v3 = Math.floor(k[3] * 9);

const g0 = ref(g[0]);
const g1 = ref(g[1]);
const g2 = ref(g[2]);
const g3 = ref(g[3]);

const showNameModal = ref(false);
const userName = ref("");
const showCertificate = ref(false);

function check() {
  if (v0 == g0.value && v1 == g1.value && v2 == g2.value && v3 == g3.value) {
    showNameModal.value = true;
  }
}

function generateCertificate() {
  if (userName.value.trim() === "") {
    return;
  }
  showNameModal.value = false;
  showCertificate.value = true;

  nextTick(() => {
    drawCertificate();
  });
}

import { nextTick } from "vue";
import badge from "../assets/img/badge.png";

function drawCertificate() {
  const canvas = document.getElementById("certificateCanvas");
  const ctx = canvas.getContext("2d");

  canvas.width = 1600;
  canvas.height = 1200;
  document.fonts.load("32px Bitter").then(() => {
    ctx.fillStyle = "#e6e6ff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.strokeStyle = "#4547bf";
    ctx.lineWidth = 20;
    ctx.strokeRect(40, 40, canvas.width - 80, canvas.height - 80);

    ctx.font = "48px 'Bitter'";
    ctx.fillStyle = "#333";
    ctx.textAlign = "center";
    ctx.fillText("Certificate of Achievement", canvas.width / 2, 300);
    let base_image = new Image();
    base_image.src = badge;
    base_image.onload = function () {
      ctx.drawImage(base_image, 150, 150, 200, 280);
      ctx.font = "100px 'Bitter'";
      ctx.fillStyle = "#000";
      ctx.fillText(userName.value.trim(), canvas.width / 2, 500);

      ctx.font = "48px 'Bitter'";
      ctx.fillStyle = "#333";
      ctx.fillText(
        "For successfully completing the maxigator.fr challenge",
        canvas.width / 2,
        700
      );

      ctx.beginPath();
      ctx.moveTo(canvas.width / 2 - 200, 900);
      ctx.lineTo(canvas.width / 2 + 200, 900);
      ctx.stroke();

      ctx.font = "40px 'Bitter'";
      ctx.fillText("From Maxigator", canvas.width / 2, 960);

      const date = new Date();
      ctx.font = "36px 'Bitter'";
      ctx.fillText(
        `Date: ${date.toLocaleDateString()}`,
        canvas.width / 2,
        1040
      );

      setTimeout(() => {
        const downloadLink = document.createElement("a");
        downloadLink.download = "certificate.png";
        downloadLink.href = canvas.toDataURL("image/png");
        downloadLink.textContent = "Download Your Certificate";
        downloadLink.className =
          "mt-4 bg-gray-500 text-white px-4 py-2 rounded text-center w-1/2 mx-auto block";
        const certificateContainer = canvas.parentNode;
        downloadLink.onclick = () => {
          showCertificate.value = false;
        };
        certificateContainer.appendChild(downloadLink);
        const discardBtn = document.createElement("button");
        discardBtn.textContent = "Discard";
        discardBtn.className =
          "mt-4 bg-gray-500 text-white px-4 py-2 rounded text-center w-1/2 mx-auto block";
        discardBtn.onclick = () => {
          showCertificate.value = false;
        };
        certificateContainer.appendChild(discardBtn);
      }, 0);
    };
  });
}
</script>

<style>
@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fade-in {
  animation: fade-in 0.3s ease-out;
}
</style>

<template>
  <div
    class="flex justify-center flex-col items-center overflow-hidden w-full relative"
    style="z-index: 0; height: 500px"
    id="mainC"
    ref="mainC"
  >
    <button
      @click="reset"
      class="absolute left-0 top-0 p-5 text-lg font-semibold"
      v-if="simulating"
    >
      {{ t("reset") }}
    </button>
    <h2
      ref="title"
      class="text-5xl md:text-7xl text-white text-center flex justify-center whitespace-nowrap select-none mb-7"
      @pointerdown="checkDbl()"
    >
      {{ t("socials") }}
    </h2>
    <a
      ref="link1"
      :href="'https://github.com/maxigator'"
      class="flex items-center cursor-pointer text-center justify-center select-none mb-5"
      target="_blank"
    >
      <img
        src="../assets/github.svg"
        class="w-5 h-5 whitesvg mr-5"
        alt="GitHub logo"
        loading="lazy"
      />
      <span>Github</span>
    </a>
    <a
      ref="link2"
      :href="'https://open.spotify.com/user/y1xv6qnm0b082hpatunsn25gq?si=40f3f68e26054bf0'"
      class="flex items-center cursor-pointer text-center justify-center select-none mb-5"
      target="_blank"
    >
      <img
        src="../assets/spotify.svg"
        class="w-5 h-5 whitesvg mr-5"
        alt="Spotify logo"
        loading="lazy"
      />
      <span>Spotify</span>
    </a>
    <a
      ref="link3"
      :href="'mailto:contact,,,maxigator.fr'.replace(',,,', '@')"
      class="flex items-center cursor-pointer text-center justify-center select-none"
      target="_blank"
    >
      <img
        src="../assets/envelope-solid.svg"
        class="w-5 h-5 whitesvg mr-5"
        alt="Mail icon"
        loading="lazy"
      />
      <span>{{"contact,,,maxigator.fr".replace(',,,', '@')}}</span>
    </a>
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, nextTick } from "vue";
import Matter from "matter-js";
import { t, k, getLang, getOo } from "../lang.js";

let f = Math.floor(k[2] * 3) + 1;
if (!getOo()) {
  f = 0;
}

const title = ref(null);
const link1 = ref(null);
const link2 = ref(null);
const link3 = ref(null);

let engine;
let runner;
let mouseConstraint;
let isMouseDown = false;
let activeBody = null;
let mousePosition = { x: 0, y: 0 };
let touchtimeout = null;
let simulating = ref(false);
const mainC = ref(null);

function checkDbl() {
  if (touchtimeout) {
    clearTimeout(touchtimeout);
    touchtimeout = null;
    gravity();
  } else {
    touchtimeout = setTimeout(() => {
      touchtimeout = null;
    }, 300);
  }
}

let upd = null;

function update(elements, Matter, top) {
  elements.forEach((body) => {
    const { x, y } = body.position;
    const angle = body.angle;
    if (x > document.body.clientWidth || x < 0 || y > 500 || y < 0) {
      Matter.Body.setPosition(
        body,
        Matter.Vector.create(document.body.clientWidth / 2, 250)
      );
      Matter.Body.setSpeed(body, 0);
    }
    body.el.style.left = `${x - body.el.offsetWidth / 2}px`;
    body.el.style.top = `${y - body.el.offsetHeight / 2 - top}px`;
    body.el.style.transform = `rotate(${angle}rad)`;
  });

  upd = requestAnimationFrame(() => update(elements, Matter, top));
}

async function gravity() {
  if (simulating.value) {
    return;
  }
  simulating.value = true;
  title.value.oncontextmenu = null;
  engine = Matter.Engine.create();
  engine.world.gravity.y = 1;

  onResize();

  const elements = [];

  async function createBody(elRef, heavy) {
    const el = elRef.value;

    const rect = el.getBoundingClientRect();

    el.style.left = `${rect.left}px`;
    el.style.top = `${rect.top - mainC.value.getBoundingClientRect().top}px`;
    el.style.userSelect = "none";
    el.style.draggable = "false";

    const body = Matter.Bodies.rectangle(
      rect.left + rect.width / 2,
      rect.top - mainC.value.getBoundingClientRect().top + rect.height / 2,
      rect.width,
      rect.height,
      {
        restitution: heavy ? 1 : 0,
        friction: heavy ? 0 : 0.1,
        frictionStatic: heavy ? 0 : 0.5,
        frictionAir: heavy ? 0 : 0.01,
        density: heavy ? 0.0001 : 0.001,
      }
    );
    body.el = el;

    Matter.World.add(engine.world, body);
    elements.push(body);
    requestAnimationFrame(() => {
      el.style.position = "absolute";
      el.style.margin = 0;
    });
  }

  await createBody(title);
  await createBody(link1, f == 1);
  await createBody(link2, f == 2);
  await createBody(link3, f == 3);

  // Remove links to prevent navigation and adjust styles
  [link1.value, link2.value, link3.value].forEach((el) => {
    el.setAttribute("oldhref", el.getAttribute("href"));
    el.removeAttribute("href");
    el.style.cursor = "grab";
    el.style.userSelect = "none";
  });

  title.value.style.cursor = "grab";
  title.value.style.userSelect = "none";

  // Runner to update the engine
  runner = Matter.Runner.create();
  Matter.Runner.run(runner, engine);

  update(elements, Matter, 0);

  // Handle mouse events
  window.addEventListener("pointerdown", handleMouseDown);
  window.addEventListener("pointermove", handleMouseMove);
  window.addEventListener("pointerup", handleMouseUp);

  // Handle window resize to adjust boundaries
  window.addEventListener("resize", onResize);
}

function handleMouseDown(event) {
  mousePosition = {
    x: event.clientX,
    y: event.clientY - mainC.value.getBoundingClientRect().top,
  };
  const bodies = Matter.Composite.allBodies(engine.world);

  // Use Matter.Query.point to find bodies under the mouse
  const foundBodies = Matter.Query.point(bodies, mousePosition);

  if (foundBodies.length > 0) {
    activeBody = foundBodies[0];
    isMouseDown = true;

    // Calculate the local point where the mouse is on the body
    const offset = {
      x: mousePosition.x - activeBody.position.x,
      y: mousePosition.y - activeBody.position.y,
    };

    // Create a constraint between the body and the mouse position
    mouseConstraint = Matter.Constraint.create({
      pointA: mousePosition,
      bodyB: activeBody,
      pointB: offset,
      stiffness: 0.98,
    });

    Matter.World.add(engine.world, mouseConstraint);

    if (activeBody.el) {
      activeBody.el.style.cursor = "grabbing";
    }
  }
}

function handleMouseMove(event) {
  mousePosition = {
    x: event.clientX,
    y: event.clientY - mainC.value.getBoundingClientRect().top,
  };
  if (isMouseDown && mouseConstraint) {
    mouseConstraint.pointA = { x: mousePosition.x, y: mousePosition.y };
  }
}

function handleMouseUp(event) {
  if (isMouseDown) {
    isMouseDown = false;
    if (mouseConstraint) {
      Matter.World.remove(engine.world, mouseConstraint);
      mouseConstraint = null;

      if (activeBody && activeBody.el) {
        activeBody.el.style.cursor = "grab";
      }
      activeBody = null;
    }
  }
}

function onResize() {
  // Adjust boundaries on window resize
  const world = engine.world;

  // Remove existing boundaries
  const boundaries = world.bodies.filter((body) => body.isStatic);
  Matter.World.remove(world, boundaries);

  // Recreate the boundaries with new window dimensions
  const ground = Matter.Bodies.rectangle(
    document.body.clientWidth / 2,
    1000,
    document.body.clientWidth,
    1000,
    {
      isStatic: true,
      render: { visible: false },
    }
  );
  const leftWall = Matter.Bodies.rectangle(-500, 250, 1000, 500, {
    isStatic: true,
    render: { visible: false },
  });
  const rightWall = Matter.Bodies.rectangle(
    document.body.clientWidth + 500,
    250,
    1000,
    500,
    {
      isStatic: true,
      render: { visible: false },
    }
  );
  const ceiling = Matter.Bodies.rectangle(
    document.body.clientWidth / 2,
    -500,
    document.body.clientWidth,
    1000,
    {
      isStatic: true,
      render: { visible: false },
    }
  );

  Matter.World.add(world, [ground, leftWall, rightWall, ceiling]);
}

function reset() {
  if (!simulating.value) {
    return;
  }
  simulating.value = false;
  Matter.Runner.stop(runner);
  Matter.World.clear(engine.world);
  engine = null;
  runner = null;

  window.removeEventListener("pointerdown", handleMouseDown);
  window.removeEventListener("pointermove", handleMouseMove);
  window.removeEventListener("pointerup", handleMouseUp);
  window.removeEventListener("resize", onResize);
  cancelAnimationFrame(upd);
  setTimeout(() => {
    [title, link1, link2, link3].forEach((el) => {
      el.value.style.position = "static";
      el.value.style.left = "auto";
      el.value.style.top = "auto";
      el.value.style.transform = "none";
      el.value.style.removeProperty("margin");
      el.value.style.userSelect = "auto";
      el.value.style.cursor = "default";

      if (el.value.getAttribute("oldhref")) {
        el.value.setAttribute("href", el.value.getAttribute("oldhref"));
        el.value.removeAttribute("oldhref");
        el.value.style.cursor = "pointer";
      }
    });
  }, 0);
}

onMounted(() => {
  let lastTap = 0;
  document
    .getElementById("mainC")
    .addEventListener("touchend", function (event) {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTap;
      if (tapLength < 500 && tapLength > 0) {
        event.preventDefault();
      }
      lastTap = currentTime;
    });

  document.querySelectorAll("img").forEach((img) => {
    img.setAttribute("draggable", "false");
    img.addEventListener("dragstart", (e) => e.preventDefault());
  });
});
</script>

<style scoped>
#mainC * {
  touch-action: none !important;
  user-select: none !important;
}
</style>

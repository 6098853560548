<template>
  <div
    :id="project.title"
    class="transition-all relative group float-start z-10 w-full shadow-2xl odd:bg-neutral-950 even:bg-neutral-900 rounded-xl"
  >
    <div
      class="md:group-odd:float-start md:group-even:float-end flex flex-col 2xl:w-auto 2xl:group-even:pl-10 2xl:group-even:ml-3 2xl:group-odd:mr-10"
    >
      <div
        class="md:flex group-even:flex-row-reverse md:group-odd:float-start md:group-even:float-end md:group-even:ml-10 md:group-odd:mr-10"
      >
        <div
          class="flex md:flex-1 flex-col w-full h-[calc(18rem+3.5rem)] md:group-even:items-end justify-center hover:underline"
        >
          <a :href="project.link" target="_blank">
            <img
              :src="project.img"
              class="p-5 md:pt-2 pt-10 pb-0 h-72 w-full md:h-72 object-contain rounded-md md:w-72 flex-1 transition-all rounded-b-none"
              :class="spec ? 'animate-[pulseLess_1s_infinite]' : 'animate-none'"
              :alt="t('projects.' + project.title + '.title') + ' logo'"
              loading="lazy"
            />
          </a>
          <a
            :href="project.link"
            target="_blank"
            class="relative pb-3 z-50 pt-5 md:pt-0 md:w-72 h-18 flex justify-center items-center text-center text-xl group-odd:rounded-bl-md group-even:rounded-br-md"
            >{{ t("projects.open") }}</a
          >
        </div>
        <div
          class="flex md:flex-col flex-row justify-between md:group-even:items-end md:group-even:text-right items-start md:justify-start pt-8 pb-10 md:mx-6 w-full px-7 md:px-0"
        >
          <div class="md:flex md:flex-col-reverse">
            <div
              class="transition-all float-right md:float-none md:mt-4 space-y-2 text-gray-100 mt-1"
            >
              <a
                v-for="tech in techs"
                class="flex items-center hover:underline flex-row-reverse md:flex-row md:group-even:flex-row-reverse"
                :href="tech.url"
                target="_blank"
              >
                <img
                  :src="tech.icon"
                  class="w-5 h-5 md:mr-2 md:ml-0 ml-2 md:group-even:ml-2 md:group-even:mr-0 object-contain"
                  :alt="t('techs.' + tech.title) + ' icon'"
                  loading="lazy"
                />
                <div class="text-right">{{ t("techs." + tech.title) }}</div>
              </a>
            </div>
            <div>
              <a
                :href="project.link"
                target="_blank"
                class="text-4xl font-semibold hover:underline text-white"
              >
                {{ t("projects." + project.title + ".title") }}
              </a>
              <br />
              <h4 class="text-lg text-gray-300 mt-3">{{
                t("projects." + project.title + ".desc")
              }}</h4>
              <p
                v-html="t('projects.' + project.title + '.longdesc')"
                class="mb-2 text-gray-400"
              ></p>
            </div>
          </div>
          <div
            class="absolute right-4 md:right-auto md:group-odd:right-4 md:group-even:left-4 bottom-4 text-gray-500"
          >
            {{ project.date }}
          </div>
        </div>
      </div>
    </div>
    <!--div
      class="p-8 relative md:group-even:[text-align-last:right] text-justify text-md h-full mt-16"
    >
      <p v-html="t('projects.' + project.title + '.longdesc')" class="mb-2"></p>
    </!--div-->
  </div>
</template>

<script setup>
import { t } from "../lang.js";
import { ref, toRef } from "vue";
const props = defineProps(["project", "spec", "componentKey"]);
const { componentKey, project, spec } = props;
const { techs } = project;
</script>

<style scoped>
@keyframes pulseLess {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
}
</style>

<template>
  <div class="w-full" @click="info = false">
    <div class="pb-24 pt-24 relative">
      <h2
        class="text-5xl md:text-7xl text-white text-center left-0 right-0 mb-10 flex justify-center"
      >
        {{ t("myprojects") }}
      </h2>
      <h3
        class="mx-5 md:mx-24 mb-10 justify-center items-center flex text-center"
      >
        {{ t("projectsintro") }}
        <div class="group relative w-10 ml-2 mr-2 z-50">
          <div
            class="bg-neutral-600 rounded-full flex justify-center items-center lg:w-5 lg:h-5 w-7 h-7 lg:text-xs font-bold cursor-help"
          >
            i
          </div>
          <div
            class="flex-col w-72 divide-x-2 divide-[#121212] group-hover:flex group-active:flex hidden absolute lg:right-auto rounded-tr-none lg:rounded-tl-none lg:rounded-tr-xl right-3 rounded-xl overflow-hidden"
          >
            <a
              :href="te.url"
              target="_blank"
              v-for="(te, i) in distribTech"
              class="h-10 relative flex bg-neutral-800 group/last"
            >
              <div
                class="absolute left-0 bottom-0 top-0 bg-slate-800 border-slate-950"
                :style="'width: calc(' + (te.prop / maxProp) * 100 + '%)'"
                :class="
                  (i > 0
                    ? distribTech[i - 1].prop > te.prop
                      ? 'border-t-2'
                      : ''
                    : '') +
                  (i < distribTech.length - 1
                    ? distribTech[i + 1].prop >= te.prop
                      ? ' border-b-2'
                      : ''
                    : '')
                "
              >
                <span
                  class="absolute z-1 w-10 h-10 p-2 flex justify-center items-center font-bold text-xs"
                  :class="
                    (18 * te.prop) / maxProp > 15.5 ? 'right-0' : '-right-10'
                  "
                  >{{ Math.round((te.prop / maxProp) * 100) }}%</span
                >
              </div>
              <span
                class="absolute z-1 h-10 p-2 flex justify-center items-center bottom-0"
                ><img :src="te.icon" :alt="te.title" class="w-5" />
                <div
                  class="group-hover/last:block hidden ml-3 text-xs bg-neutral-800 px-2 py-1 rounded-lg"
                >
                  {{ t("techs." + te.title) }}
                </div></span
              >
            </a>
          </div>
        </div>
      </h3>
      <div
        class="mt-8 md:mt-16 px-5 lg:px-10 grid grid-cols-1 gap-x-8 lg:gap-x-0 gap-y-8 md:grid-cols-1"
      >
        <Card
          :componentKey="componentKey"
          :project="project"
          v-for="(project, i) in reProjects"
          :spec="i == spec"
          :fullopen="project.open"
        >
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { t, getLang, getOo, k } from "../lang.js";
import Card from "../components/Card.vue";
import { projects, tech } from "./projects.js";
import { ref, reactive } from "vue";
const props = defineProps(["componentKey"]);
const componentKey = props.componentKey;

let openedProject = ref(null);
const reProjects = reactive(projects);

let spec = -1;
if (getOo()) {
  spec = Math.floor(k[1] * (projects.length - 1)) + 1;
}
Object.entries(tech).forEach((t) => {
  tech[t[0]].prop = projects.reduce(
    (a, b) => a + (b.techs.includes(t[1]) ? 1 : 0),
    0
  );
});
let sortedTech = Object.values(tech);
let maxProp = Math.max(...sortedTech.map((t) => t.prop));
sortedTech.sort((a, b) => b.prop - a.prop);

let distribTech = sortedTech;
let add = true;
//sortedTech.forEach((t, i) => {
//  if (add) {
//    distribTech.unshift(t);
//  } else {
//    distribTech.push(t);
//  }
//  add = !add;
//});
const info = ref(false);
</script>

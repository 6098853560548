<template>
  <div
    class="flex space-x-5 pt-7 sm:pl-10 h-25 fixed top-0 left-0 right-0 items-center bg-[#121212] pb-5 justify-center sm:justify-start"
    style="z-index: 50"
    id="header"
    :key="componentKey"
  >
    <a
      href="#home"
      class="cursor-pointer flex items-center transition-all"
      :class="currentpage == 'home' ? 'active' : ''"
    >
      <img src="/COW.webp" class="h-10 w-10 mr-5" alt="Logo" />{{
        t("nav.home")
      }}
    </a>
    <a
      href="#about"
      class="cursor-pointer transition-all"
      :class="currentpage == 'about' ? 'active' : ''"
    >
      {{ t("nav.projects") }}
    </a>
    <a
      href="#contact"
      class="cursor-pointer transition-all"
      :class="currentpage == 'contact' ? 'active' : ''"
    >
      {{ t("nav.socials") }}
    </a>
    <a
      href="#experiment"
      class="cursor-pointer transition-all"
      :class="currentpage == 'experiment' ? 'active' : ''"
    >
      {{ t("nav.experiment") }}
    </a>
    <a
      href="#oo"
      @click="openEgg"
      class="cursor-pointer transition-all hover:text-white hover:opacity-100 select-none"
      :class="
        currentpage == 'oo'
          ? 'opacity-100 text-white active'
          : 'opacity-75 text-black'
      "
      v-if="getOo()"
    >
      [?]
    </a>
    <div
      class="sm:absolute sm:right-12 sm:bottom-auto sm:left-auto fixed bottom-3 left-0"
    >
      <label for="lang" class="text-transparent absolute -z-10">{{
        t("nav.lang")
      }}</label>
      <select
        :key="componentKey"
        id="lang"
        name="lang"
        class="sm:p-1 px-2 py-4 bg-[#121212] text-white"
        @contextmenu.prevent="setOoM()"
        @pointerdown="startTouch"
        @pointerup="clearTouch"
        @pointerleave="clearTouch"
        @pointerout="clearTouch"
        @input="setLangM($event.target.value)"
        :value="lang"
      >
        <option value="en">English</option>
        <option value="fr">Français</option>
      </select>
    </div>
  </div>
  <div class="divide-y-2 divide-neutral-800">
    <div
      v-for="route in routes"
      class="w-full page"
      :id="route.id"
      :key="componentKey"
    >
      <component :is="route.component" :key="componentKey" />
    </div>
  </div>
  <div class="w-full text-center text-gray-500 p-2 px-32">
    {{ t("footnote") }}
  </div>

  <a
    class="fixed right-0 bottom-0 p-5 text-2xl bg-opacity-30 bg-neutral-600 rounded-tl-lg transition-all z-50"
    href="#home"
    :class="currentpage == 'home' ? 'opacity-0' : 'opacity-100'"
    >🠉</a
  >
  <div
    class="fixed bottom-0 left-0 flex-col space-y-5 md:items-start items-center md:flex hidden bg-opacity-50 bg-black w-15 hover:w-64 overflow-x-hidden transition-all z-10"
    v-show="!contactPage"
  >
    <a
      href="https://github.com/maxigator"
      class="flex items-center cursor-pointer px-5 pt-5 w-full md:w-28 text-center justify-center md:justify-start"
      target="_blank"
      ><img
        src="./assets/github.svg"
        class="w-5 h-5 whitesvg mr-5"
        alt="Github icon"
      /><span>GitHub</span></a
    >
    <a
      href="https://open.spotify.com/user/y1xv6qnm0b082hpatunsn25gq?si=40f3f68e26054bf0"
      target="_blank"
      class="flex items-center cursor-pointer px-5 w-full md:w-28 text-center justify-center md:justify-start"
      ><img
        src="./assets/spotify.svg"
        class="w-5 h-5 whitesvg mr-5"
        alt="Spotify icon"
      /><span>Spotify</span></a
    >
    <a
      href="mailto:contact@maxigator.fr"
      class="flex items-center cursor-pointer px-5 pb-5 w-full md:w-64 text-center justify-center md:justify-start"
      ><img
        src="./assets/envelope-solid.svg"
        class="w-5 h-5 whitesvg mr-5"
        alt="Mail icon"
      /><span>contact@maxigator.fr</span></a
    >
  </div>
</template>

<script>
//import { useRouter } from "vue-router";
import { t, setLang, getLang, getOo, setOo } from "./lang.js";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";
import Experiment from "@/views/Experiment.vue";
import oo from "@/views/oo.vue";

export default {
  data() {
    return {
      contactPage: false,
      lang: "en",
      componentKey: 0,
      touchtimeout: null,
      currentpage: 0,
      ooBefore: false,
    };
  },
  setup() {
    const routes = [
      {
        id: "home",
        component: Home,
      },
      {
        id: "about",
        component: About,
      },
      {
        id: "contact",
        component: Contact,
      },
      //{
      //  id: "experiment",
      //  component: Experiment,
      //},
    ];
    return { t, getLang, getOo, setOo, routes };
  },
  mounted() {
    this.lang = getLang();
    let npage = this.currentpage;
    document.addEventListener("scroll", () => {
      const elements = document.querySelectorAll(".page");
      [...elements].reverse().forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.bottom >= window.innerHeight / 1.5) {
          npage = el.id;
        }
      });
      if (npage != this.currentpage) {
        this.currentpage = npage;
      }
    });
  },
  methods: {
    openEgg() {
      if (!this.routes.find((e) => e.id == "oo")) {
        this.routes.push({
          id: "oo",
          component: oo,
        });
        this.$forceUpdate();
      }
      this.$nextTick(() => {
        //scroll to the bottom of the page
        window.scrollTo(0, document.body.scrollHeight);
      });
    },
    startTouch(e) {
      this.ooBefore = this.getOo();
      if (window.safari !== undefined) {
        return;
      }
      let self = this;
      this.touchtimeout = setTimeout(() => {
        self.setOoM(!self.ooBefore);
      }, 1500);
    },
    clearTouch(e) {
      clearTimeout(this.touchtimeout);
    },
    setLangM(l) {
      setLang(l);
      this.lang = l;
      this.$forceUpdate();
      this.componentKey += 1;
    },
    setOoM() {
      if (this.getOo() == !this.ooBefore) {
        return;
      }
      clearTimeout(this.touchtimeout);
      setOo(!this.ooBefore);
      if (!this.getOo() && this.routes.find((e) => e.id == "oo")) {
        this.routes.splice(this.routes.length - 1, 1);
      }
      this.$forceUpdate();
      this.componentKey += 1;
      this.$nextTick(() => {
        this.componentKey += 1;
      });
    },
  },
};
</script>
<style>
.active {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.7);
}
select {
  user-select: none;
  -webkit-user-select: none;
}
#header {
  touch-action: none !important;
}
* {
  touch-action: pan-y !important;
  user-select: none !important;
}
html {
  scroll-behavior: smooth;
}
</style>

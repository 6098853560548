<template>
  <div
    class="absolute bottom-0 border rounded-full border-neutral-950"
    style="height: 15vh; width: 15vh"
  >
    <div id="container"></div>
  </div>
</template>

<script setup>
import { k, getLang, getOo } from "../lang.js";
import { onMounted, onBeforeUnmount, ref } from "vue";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";

let height = 0.15 * window.innerHeight;
let width = 0.15 * window.innerHeight;
function onResize(event) {
  height = 0.15 * window.innerHeight;
  width = 0.15 * window.innerHeight;
  camera = new THREE.PerspectiveCamera(75, width / height, 1, 10000);
  camera.position.z = 1000;

  renderer.setSize(width, height);
  setupPostProcessing();
}
let loaded = false;
const c = Math.floor(k[3] * 9);
let container = null;
let scene, camera, renderer;
let particles, particleMaterial, bigparticles, bigparticleMaterial;
const particleCount = 500;
let mouse = [new THREE.Vector3()];
let attract = false;
let centerPoint = new THREE.Vector3(0, 0, 0);
let lines,
  maxLines = 5;
let composer, renderPass, bloomPass;
let lastRenderTime = 0;
const targetFrameTime = 1000 / 60;
let fieldForce = 1;
const digits = [
  // 1
  [
    [-1, 1],
    [0, 2],
    [0, 1 / 2],
    [0, -1 / 2],
    [0, -2],
  ],

  // 2
  [
    [-1, 2],
    [1 / 4, 2],
    [1, 1],
    [0, 0],
    [-1, -1],
    [-1, -2],
    [0, -2],
    [1, -2],
  ],

  // 3
  [
    [-1, 2],
    [0, 2],
    [1, 1],
    [1, 0],
    [0, 0],
    [1, 0],
    [1, -1],
    [0, -2],
    [-1, -2],
  ],

  // 4
  [
    [-1, 2],
    [-1, 1],
    [-1, 0],
    [0, 0],
    [1, 0],
    [1, 1],
    [1, 2],
    [1, -1],
    [1, -2],
  ],

  // 5
  [
    [1, 2],
    [-1, 2],
    [-1, 0.5],
    [-0.5, 0],
    [0.5, 0],
    [1, -0.5],
    [1, -2],
    [-1, -2],
  ],

  // 6
  [
    [1, 2],
    [0, 2],
    [-1, 1],
    [-1, 0],
    [-1, -1],
    [0, -2],
    [1, -2],
    [2, -1],
    [1, 0],
    [0, 0],
  ],

  // 7
  [
    [-1, 2],
    [0, 2],
    [1, 2],
    [1 / 2, 0],
    [0, -2],
  ],

  // 8
  [
    [-1 / 2, 0],
    [-1, 1],
    [-1 / 2, 2],
    [1 / 2, 2],
    [1, 1],
    [1 / 2, 0],
    [-1 / 2, 0],
    [-1, -1],
    [-1 / 2, -2],
    [1 / 2, -2],
    [1, -1],
    [1 / 2, 0],
  ],

  // 9
  [
    [-1, -2],
    [0, -2],
    [1, -1],
    [1, 0],
    [1, 1],
    [0, 2],
    [-1, 2],
    [-2, 1],
    [-1, 0],
    [0, 0],
  ],
];

function setupPostProcessing() {
  var parameters = {
    minFilter: THREE.LinearFilter,
    magFilter: THREE.LinearFilter,
    format: THREE.RGBAFormat,
    stencilBuffer: false,
    alpha: true,
  };

  var renderTarget = new THREE.WebGLRenderTarget(width, height, parameters);
  composer = new EffectComposer(renderer, renderTarget);

  renderPass = new RenderPass(scene, camera);
  //renderer.setClearColor(0x000000, 0);
  renderPass.clear = true;
  composer.addPass(renderPass);

  bloomPass = new UnrealBloomPass(
    new THREE.Vector2(width, height),
    1.5,
    0.4,
    0.85
  );
  bloomPass.threshold = 0.21;
  bloomPass.strength = 1.5;
  bloomPass.radius = 0.55;
  composer.addPass(bloomPass);
}

onMounted(() => {
  container = document.getElementById("container");

  load();
  //if (container.getBoundingClientRect().top < height * 2 + 10) {
  //  load();
  //}
  //document.addEventListener("scroll", () => {
  //  if (container.getBoundingClientRect().top < height * 2 + 10) {
  //    load();
  //  } else {
  //    unload();
  //  }
  //});
});

onBeforeUnmount(() => {
  unload();
});

function load() {
  if (loaded) return;
  loaded = true;
  init();
  animate(0);
  window.addEventListener("mousedown", onMouseDown, false);
  window.addEventListener("mouseup", onMouseUp, false);
  window.addEventListener("mousemove", onMouseMove, false);

  window.addEventListener("touchstart", onMouseDown, false);
  window.addEventListener("touchend", onMouseUp, false);
  window.addEventListener("touchmove", onMouseMove, false);

  window.addEventListener("resize", onResize, false);
}

function unload() {
  if (!loaded) return;
  loaded = false;
  if (renderer?.domElement) {
    container.removeChild(renderer.domElement);
    renderer = null;
  }
  cancelAnimationFrame(animationFrameID);
  window.removeEventListener("mousedown", onMouseDown);
  window.removeEventListener("mouseup", onMouseUp);
  window.removeEventListener("mousemove", onMouseMove);

  window.removeEventListener("touchstart", onMouseDown);
  window.removeEventListener("touchend", onMouseUp);
  window.removeEventListener("touchmove", onMouseMove);
  window.removeEventListener("resize", onResize);
}

let animationFrameID;

function init() {
  scene = new THREE.Scene({ alpha: true });
  camera = new THREE.PerspectiveCamera(75, width / height, 1, 10000);
  camera.position.z = 1000;

  renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.setSize(width, height);
  container.appendChild(renderer.domElement);

  const geometry = new THREE.BufferGeometry();
  let positions = [];
  const colors = [];
  const color = new THREE.Color();

  for (let i = 0; i < particleCount; i++) {
    const x = Math.random() * 2 - 1;
    const y = Math.random() * 2 - 1;
    const z = Math.random() * 2 - 1;
    positions.push(x * 500, y * 500, z * 500);

    color.setHSL(i / particleCount, 1.0, 0.5);
    colors.push(color.r, color.g, color.b);
  }

  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(positions, 3)
  );
  geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

  particleMaterial = new THREE.PointsMaterial({ size: 15, vertexColors: true });
  particles = new THREE.Points(geometry, particleMaterial);

  const lineGeometry = new THREE.BufferGeometry();
  positions = new Float32Array(maxLines * 2 * 3);
  lineGeometry.setAttribute(
    "position",
    new THREE.BufferAttribute(positions, 3)
  );
  lines = [];
  for (let i = 0; i < maxLines; i++) {
    const lineGeometry = new THREE.BufferGeometry();
    const positions = new Float32Array(2 * 3);
    lineGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    const line = new THREE.Line(
      lineGeometry,
      new THREE.LineBasicMaterial({
        color: parseInt(randomColor.replace(/^#/, ""), 16),
      })
    );
    lines.push(line);
    scene.add(line);
  }
  if (getOo()) {
    const biggeometry = new THREE.BufferGeometry();
    const bigcolors = [];
    const bigcolor = new THREE.Color();
    let bigpositions = [];
    for (let i = 0; i < digits[c].length; i++) {
      const x = Math.random() * 2 - 1;
      const y = Math.random() * 2 - 1;
      const z = Math.random() * 2 - 1;
      bigpositions.push(x * 500, y * 500, z * 500);

      bigcolor.setHSL(i / digits[c].length, 1.0, 0.5);
      bigcolors.push(bigcolor.r, bigcolor.g, bigcolor.b);
    }
    biggeometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(bigpositions, 3)
    );
    biggeometry.setAttribute(
      "color",
      new THREE.Float32BufferAttribute(bigcolors, 3)
    );
    bigparticleMaterial = new THREE.PointsMaterial({
      size: 0,
      vertexColors: true,
    });
    bigparticles = new THREE.Points(biggeometry, bigparticleMaterial);
    //particles.add(bigparticles);
  }
  scene.add(particles);

  setupPostProcessing();
}

function updateLines() {
  let particleIndices = new Set();

  lines.forEach((line, i) => {
    if (Math.random() < 0.1) {
      let idx1, idx2;
      do {
        idx1 = Math.floor(Math.random() * particleCount);
      } while (particleIndices.has(idx1));
      particleIndices.add(idx1);

      do {
        idx2 = Math.floor(Math.random() * particleCount);
      } while (particleIndices.has(idx2) || idx1 === idx2);
      particleIndices.add(idx2);

      const positions = line.geometry.attributes.position.array;
      positions[0] = particles.geometry.attributes.position.array[idx1 * 3];
      positions[1] = particles.geometry.attributes.position.array[idx1 * 3 + 1];
      positions[2] = particles.geometry.attributes.position.array[idx1 * 3 + 2];
      positions[3] = particles.geometry.attributes.position.array[idx2 * 3];
      positions[4] = particles.geometry.attributes.position.array[idx2 * 3 + 1];
      positions[5] = particles.geometry.attributes.position.array[idx2 * 3 + 2];

      line.geometry.attributes.position.needsUpdate = true;
    }
  });
}

function animate(currentTime) {
  updateLines();
  animationFrameID = requestAnimationFrame(animate);
  const delta = currentTime - lastRenderTime;
  if (delta < targetFrameTime) {
    return;
  }

  let positions = particles.geometry.attributes.position.array;
  let bigpositions;
  if (getOo()) {
    bigpositions = bigparticles.geometry.attributes.position.array;
  }

  for (let i = 0; i < positions.length; i += 3) {
    let amp = getOo() ? 40 : 0;
    let particlePosition = new THREE.Vector3(
      positions[i] + (Math.random() - 1 / 2) * amp,
      positions[i + 1] + (Math.random() - 1 / 2) * amp,
      positions[i + 2] + (Math.random() - 1 / 2) * amp
    );

    if (!getOo()) {
      let direction = centerPoint
        .clone()
        .sub(particlePosition)
        .normalize()
        .multiplyScalar(5);
      particlePosition.add(direction);
    }
    if (attract) {
      mouse.forEach((point) => {
        let direction = point.clone().sub(particlePosition);
        let distance = direction.length();
        let forceMagnitude = 10000 / (distance + 200);
        direction.normalize().multiplyScalar(forceMagnitude);
        particlePosition.add(direction);
      });
    }

    for (let j = 0; j < positions.length; j += 3) {
      if (i !== j) {
        let otherPosition = new THREE.Vector3(
          positions[j],
          positions[j + 1],
          positions[j + 2]
        );
        let distance = particlePosition.distanceTo(otherPosition);
        if (distance < 100) {
          let repulsion = particlePosition
            .clone()
            .sub(otherPosition)
            .normalize()
            .multiplyScalar(fieldForce);
          particlePosition.add(repulsion);
        }
      }
    }
    if (getOo()) {
      let j = Math.floor((i / positions.length) * digits[c].length) * 3;
      let otherPosition = new THREE.Vector3(
        bigpositions[j],
        bigpositions[j + 1],
        bigpositions[j + 2]
      );
      let distance = particlePosition.distanceTo(otherPosition);
      let repulsion = otherPosition
        .clone()
        .sub(particlePosition)
        .normalize()
        .multiplyScalar(2000 / distance);
      particlePosition.add(repulsion);

      let nj = j + 3;
      if (nj < digits[c].length * 3) {
        otherPosition = new THREE.Vector3(
          bigpositions[nj],
          bigpositions[nj + 1],
          bigpositions[nj + 2]
        );
        distance = particlePosition.distanceTo(otherPosition);
        repulsion = otherPosition
          .clone()
          .sub(particlePosition)
          .normalize()
          .multiplyScalar(4);
        particlePosition.add(repulsion);
      }
    }

    positions[i] = particlePosition.x;
    positions[i + 1] = particlePosition.y;
    positions[i + 2] = particlePosition.z;
  }

  if (getOo()) {
    for (let i = 0; i < bigpositions.length; i += 3) {
      let particlePosition = new THREE.Vector3(
        bigpositions[i],
        bigpositions[i + 1],
        bigpositions[i + 2]
      );

      let target = new THREE.Vector3(
        digits[c][i / 3][0] * 200,
        digits[c][i / 3][1] * 200,
        0
      );
      let direction = target
        .clone()
        .sub(particlePosition)
        .normalize()
        .multiplyScalar(2);
      particlePosition.add(direction);

      bigpositions[i] = particlePosition.x;
      bigpositions[i + 1] = particlePosition.y;
      bigpositions[i + 2] = particlePosition.z;
    }

    bigparticles.geometry.attributes.position.needsUpdate = true;
  }

  particles.geometry.attributes.position.needsUpdate = true;
  renderer.render(scene, camera);
  lastRenderTime = currentTime - (delta % targetFrameTime);
}

function updateMousePos(event, click) {
  if (!event) {
    return;
  }
  if (event.touches) {
    updateTouchPos(event, click);
    return;
  }
  if (
    event.clientX < container.getBoundingClientRect().left ||
    event.clientX > container.getBoundingClientRect().right ||
    event.clientY < container.getBoundingClientRect().top ||
    event.clientY > container.getBoundingClientRect().bottom
  ) {
    attract = false;
    return;
  }
  if (click) {
    attract = true;
  }
  let m = new THREE.Vector3();
  m.x =
    ((event.clientX - container.getBoundingClientRect().left) / width) * 2 - 1;
  m.y =
    -((event.clientY - container.getBoundingClientRect().top) / height) * 2 + 1;
  m.z = 0.5;
  m.unproject(camera);
  m.sub(camera.position).normalize();
  let distance = -camera.position.z / m.z;
  mouse = [camera.position.clone().add(m.multiplyScalar(distance))];
}

function updateTouchPos(event, click) {
  let filtered = [...event.touches].filter((touch) => {
    return (
      touch.clientX >= container.getBoundingClientRect().left &&
      touch.clientX <= container.getBoundingClientRect().right &&
      touch.clientY >= container.getBoundingClientRect().top &&
      touch.clientY <= container.getBoundingClientRect().bottom
    );
  });
  mouse = filtered.map((touch) => {
    let m = new THREE.Vector3();
    m.x =
      ((touch.clientX - container.getBoundingClientRect().left) / width) * 2 -
      1;
    m.y =
      -((touch.clientY - container.getBoundingClientRect().top) / height) * 2 +
      1;
    m.z = 0.5;
    m.unproject(camera);
    m.sub(camera.position).normalize();
    let distance = -camera.position.z / m.z;
    if (click) {
      attract = true;
    }
    return camera.position.clone().add(m.multiplyScalar(distance));
  });
}

function onMouseDown(event) {
  updateMousePos(event, true);
  fieldForce = 0.5;
}

function onMouseUp(event) {
  attract = false;
  fieldForce = 1;
}

function onMouseMove(event) {
  event.preventDefault();
  event.stopPropagation();
  updateMousePos(event);
}
</script>

<style scoped>
* {
  overflow: hidden !important;
  touch-action: none !important;
}
</style>

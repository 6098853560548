import OChameau from "../assets/img/ochameau.webp";

import Draph from "../assets/img/D.webp";
import BookBunker from "../assets/img/bookbunker.webp";
import WordleAI from "../assets/img/wordleai.webp";
import Tempos from "../assets/img/tempos.webp";
import Chifoumi from "../assets/img/chifoumi.webp";
import Flagle from "../assets/img/flagle.webp";
import Webdesigner from "../assets/img/awd.webp";
import Fun from "../assets/img/fun.webp";
import threedgol from "../assets/img/threedgol.webp";

import fabricjs from "../assets/img/fabricjs.webp";
import vuejs from "../assets/img/vuejs.webp";
import tailwindcss from "../assets/img/tailwindcss.webp";
import kotlin from "../assets/img/kotlin.webp";
import svelte from "../assets/img/svelte.webp";
import threejs from "../assets/img/threejs.webp";
const tech = {
  fabric: {
    title: "fabricjs",
    icon: fabricjs,
    url: "http://fabricjs.com/",
  },
  vue: {
    title: "vuejs",
    icon: vuejs,
    url: "https://vuejs.org/",
  },
  tailwind: {
    title: "tailwindcss",
    icon: tailwindcss,
    url: "https://tailwindcss.com/",
  },
  kotlin: {
    title: "kotlin",
    icon: kotlin,
    url: "https://kotlinlang.org/",
  },
  svelte: {
    title: "svelte",
    icon: svelte,
    url: "https://svelte.dev/",
  },
  threejs: {
    title: "threejs",
    icon: threejs,
    url: "https://threejs.org/",
  },
};

const projects = [
  {
    link: "https://www.maxigator.fr/draph",
    title: "Draph",
    img: Draph,
    techs: [tech.vue, tech.tailwind, tech.fabric],
		date: "02/2024"
  },
  {
    link: "https://www.artiskit.net/store",
    title: "ArtisKit Web Designer",
    img: Webdesigner,
    techs: [tech.vue, tech.tailwind, tech.fabric],
		date: "07/2021"
  },
  {
    link: "https://play.google.com/store/apps/details?id=com.maxigator.books",
    title: "Book Bunker",
    img: BookBunker,
    techs: [tech.kotlin],
		date: "08/2023"
  },
  {
    link: "https://www.maxigator.fr/flagle",
    title: "Flagle",
    img: Flagle,
    techs: [tech.vue, tech.tailwind],
		date: "03/2022"
  },
  {
    link: "https://www.maxigator.fr/wordleai",
    title: "Wordle Bot",
    img: WordleAI,
    techs: [tech.vue, tech.tailwind],
		date: "03/2022"
  },
  //{
  //  link: "https://www.maxigator.fr/ochameau",
  //  title: "OChameau",
  //  desc: "An online OCaml editor",
  //  img: OChameau,
  //  techs: [tech.vue, tech.tailwind],
  //},
  {
    link: "https://www.maxigator.fr/chifoumi",
    title: "Chifoumi",
    img: Chifoumi,
    techs: [tech.vue, tech.tailwind, tech.fabric],
		date: "04/2022"
  },
  {
    link: "https://www.maxigator.fr/tempos",
    title: "Tempos",
    img: Tempos,
    techs: [tech.vue, tech.tailwind],
		date: "05/2022"
  },
  {
    link: "https://www.maxigator.fr/3dgol",
    title: "3dgol",
    img: threedgol,
    techs: [tech.svelte, tech.threejs],
		date: "02/2024"
  },
  {
    link: "https://www.maxigator.fr/fun",
    title: "Fun",
    img: Fun,
    techs: [tech.vue, tech.tailwind, tech.fabric],
		date: "11/2022"
  },
];

export { projects, tech };

// langLoader.js
import fr from "./lang/fr.json";
import en from "./lang/en.json";
//import DOMPurify from "dompurify";

//const purifier = DOMPurify;
const langs = { fr, en };
let lang = localStorage.getItem("lang") || "en";
let oo = !!localStorage.getItem("oo");

// Function to sanitize HTML
function sanitizeHTML(htmlString) {
  return htmlString;
}

// Function to load and inject longdesc into projects
function loadLongDescs() {
  const languageKeys = Object.keys(langs);

  for (const langKey of languageKeys) {
    const projects = langs[langKey].projects;
    const modules = import.meta.glob("./lang/desc/*.html", {
      query: "?raw",
      eager: true,
    });

    for (const project of Object.values(projects)) {
      if (project.title) {
        const fileName = `${project.title}.html`;
        const importPath = `./lang/desc/${langKey}${fileName}`;
        if (modules[importPath]) {
          const htmlContent = modules[importPath].default;
          const sanitizedHTML = sanitizeHTML(htmlContent);
          project.longdesc = sanitizedHTML;
        } else {
          project.longdesc = "";
        }
      }
    }
  }
}

loadLongDescs();

// Translation function
function t(tag, nlang) {
  if (!nlang) {
    nlang = lang;
  }
  let splitted = tag.split(".");
  let val = langs[nlang][splitted[0]];
  splitted.splice(0, 1);
  while (splitted.length > 0) {
    val = val[splitted[0]];
    splitted.splice(0, 1);
  }
  if (oo) {
    if (typeof val === "string") {
      val = crypticTranslator(val);
    } else {
      val[0] = crypticTranslator(val[0]);
      val[1] = crypticTranslator(val[1]);
    }
  }
  return val;
}

function setLang(nl) {
  lang = nl;
  localStorage.setItem("lang", lang);
  loadLongDescs();
}

function crypticTranslator(input) {
  let translated = "";

  for (let i = 0; i < input.length; i++) {
    let char = input[i];
    if (Math.random() > 0.5) {
      translated += char;
      continue;
    }
    switch (char.toLowerCase()) {
      case "a":
        translated += "@";
        break;
      case "c":
        translated += "(";
        break;
      case "d":
        translated += "D";
        break;
      case "e":
        translated += "3";
        break;
      case "f":
        translated += "#";
        break;
      case "g":
        translated += "9";
        break;
      case "h":
        translated += "H";
        break;
      case "i":
        translated += "!";
        break;
      case "j":
        translated += "J";
        break;
      case "k":
        translated += "K";
        break;
      case "l":
        translated += "1";
        break;
      case "m":
        translated += "M";
        break;
      case "n":
        translated += "N";
        break;
      case "o":
        translated += "0";
        break;
      case "p":
        translated += "P";
        break;
      case "q":
        translated += "Q";
        break;
      case "r":
        translated += "R";
        break;
      case "s":
        translated += "$";
        break;
      case "t":
        translated += "7";
        break;
      case "u":
        translated += "U";
        break;
      case "v":
        translated += "V";
        break;
      case "w":
        translated += "W";
        break;
      case "x":
        translated += "X";
        break;
      case "y":
        translated += "Y";
        break;
      case "z":
        translated += "2";
        break;
      default:
        translated += char;
        break;
    }
  }

  return translated;
}

function getLang() {
  return lang;
}

function setOo(val) {
  oo = val;
  if (oo) {
    localStorage.setItem("oo", oo);
  } else {
    localStorage.removeItem("oo");
  }
}

function getOo() {
  return oo;
}

let k = [Math.random(), Math.random(), Math.random(), Math.random()];
let g = [1, 0, 1, 1];

export { t, setLang, getLang, k, g, getOo, setOo };
